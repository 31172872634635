<template>
  <article class="home-page-recommended">
    <div class="home-page-recommended__wrapper">
      <h3 class="home-page-recommended__header">Polecane warsztaty samochodowe</h3>

      <ul class="home-page-recommended__lists">
        <li
          v-for="city in homePageCities"
          class="home-page-recommended__list"
        >
          <a
            class="home-page-recommended__services-parent-name"
            :href="`https://dobrymechanik.pl/mechanicy/${city.slug}/`"
          >
            <span>{{ city.name }}</span>
          </a>
          <ul class="home-page-recommended__services">
            <li
              v-for="service in city.services"
              :key="service.name"
              class="home-page-recommended__service"
            >
              <a :href="`https://dobrymechanik.pl/uslugi/${service.slug}/${city.slug}/`">
                <span>{{ service.name }}</span>
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </article>
</template>

<script
  setup
  lang="ts"
>
import { homePageCities } from '@/models/consts';

// use
const route = useRoute();

// computed
const city = computed(() => {
  return route.params['city'] as string;
});


</script>

<style
  lang="scss"
  scoped
>
.home-page-recommended {
  display: flex;
  justify-content: center;

  &__wrapper {
    width: 100%;
    max-width: 1280px;
    padding: 0 14px;
  }

  &__header {
    margin: 0;
    font-weight: 400;
    font-size: 20px;
  }

  &__lists {
    display: flex;
    flex-wrap: wrap;
    margin: 24px 0;
    list-style: none;
    padding: 0;
    gap: 0 22px;
  }

  &__list {
    margin: 0 0 20px 0;
    display: flex;
    flex-direction: column;
  }

  &__services {
    list-style: none;
    padding: 0;

    @media (min-width: $breakpoint) {
      column-count: 2;
    }
  }

  &__services-parent-name {
    font-weight: 500;
    font-size: 14px;
    margin: 0 0 8px 0;
    color: $blue-1 !important;
    text-decoration: none;
  }

  &__service {
    min-width: 110px;
    font-size: 16px;
    line-height: 26px;
    margin: 4px 0;

    a {
      text-decoration: none;
      color: $grey-2 !important;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>