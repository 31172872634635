<template>
  <form
    class="home-page-search-modal__form"
    id="home-page-search-modal.form"
    @submit.prevent
  >
    <div class="home-page-search-modal">
      <div class="home-page-search-modal__input">
        <DmInput
          v-model="formWrapper.form.value.fields.searchServiceOrWorkspace"
          class="home-page-search__search-input"
          icon="i-search"
          id="homePageSearchServiceOrWorkspaceInput"
          label="Wyszukaj usługę lub warsztat"
          placeholder="np. Serwis klimatyzacji"
          @update:errors="formWrapper.updateErrors($event, 'searchServiceOrWorkspace')"
        />
      </div>
      <section v-if="formWrapper.form.value.fields.searchServiceOrWorkspace.length < 3">
        <h4 class="home-page-search-modal__section-header">Popularne usługi</h4>
        <ul class="home-page-search-modal__services">
          <li
            class="home-page-search-modal__service"
            v-for="service in popularServices"
          >
            <NuxtLink
              class="home-page-search-modal-city__city-link"
              :to="`/?search-step=city-or-district&service=${service.service}&service-slug=${service.slug_name}`"
            >
              <div class="home-page-search-modal__service-wrapper">

                <NuxtImg
                  :src="`/_nuxt/${service.id}.svg`"
                  height="34px"
                  width="34px"
                  :alt="service.service"
                />
                <div>
                  <h5 class="home-page-search-modal__service-name">{{ service.service }}</h5>
                  <!-- <p class="home-page-search-modal__service-category">{{ service.category }}</p> -->
                </div>

              </div>
              <i class="i-arrow-right" />
            </NuxtLink>
          </li>
        </ul>
      </section>
      <section v-if="!!foundServices?.length && formWrapper.form.value.fields.searchServiceOrWorkspace.length >= 3">
        <h4 class="home-page-search-modal__section-header">Usługi</h4>
        <ul class="home-page-search-modal__services">
          <li
            class="home-page-search-modal__service"
            v-for="(service, index) in foundServices"
            v-show="showMoreServices || index < 4"
          >
            <NuxtLink
              class="home-page-search-modal-city__city-link"
              :to="`/?search-step=city-or-district&service=${service.name}&service-slug=${service.slug_name}`"
            >
              <div class="home-page-search-modal__service-wrapper">
                <NuxtImg
                  src="/_nuxt/magnifyingGlass.svg"
                  height="26px"
                  width="26px"
                  :alt="service.name"
                />
                <div>
                  <h5 class="home-page-search-modal__service-name">{{ service.name }}</h5>
                </div>
              </div>
              <i class="i-arrow-right" />
            </NuxtLink>

          </li>
        </ul>
        <div class="home-page-search-modal__show-more-wrapper">
          <DmButton
            secondary
            v-if="!showMoreServices && foundServices.length > 4"
            :title="`Pokaż więcej (${foundServices.length})`"
            @click.prevent="showMoreServices = !showMoreServices"
          />
        </div>

        <div
          v-if="showMoreServices"
          class="home-page-search-modal__bottom-bar"
        >
          <DmButton
            title="Pokaż mniej"
            secondary
            @click.prevent="hideBottomBar"
          />
        </div>

      </section>
      <section v-if="!!foundWorkshops?.length && formWrapper.form.value.fields.searchServiceOrWorkspace.length >= 3">
        <h4 class="home-page-search-modal__section-header">Warsztaty</h4>
        <ul class="home-page-search-modal__services">
          <li
            class="home-page-search-modal__service"
            v-for="(workshop, index) in foundWorkshops"
            v-show="showMoreWorkspaces || index < 4"
          >

            <NuxtLink
              class="home-page-search-modal__service-link"
              target="_blank"
              :to="`/mechanicy/${workshop.slug_city}/${workshop.slug_name}.html`"
            >
              <div class="home-page-search-modal__service-wrapper">
                <NuxtImg
                  v-if="workshop.photo"
                  width="47px"
                  height="36px"
                  :src="workshop.photo"
                  :alt="getWorkshopName(workshop)"
                />
                <NuxtImg
                  v-else
                  width="47px"
                  height="36px"
                  src="/_nuxt/noPhotoHomepage.svg"
                />
                <div>
                  <h5 class="home-page-search-modal__service-name">{{ getWorkshopName(workshop) }}</h5>
                  <p class="home-page-search-modal__service-category"> {{ getWorkshopAddress(workshop) }}</p>
                </div>
              </div>
            </NuxtLink>
            <i class="i-arrow-right" />
          </li>
        </ul>
        <div class="home-page-search-modal__show-more-wrapper">
          <DmButton
            secondary
            v-if="!showMoreWorkspaces && foundWorkshops.length > 4"
            :title="`Pokaż więcej (${foundWorkshops.length})`"
            @click.prevent="showMoreWorkspaces = !showMoreWorkspaces"
          />
        </div>

        <div
          v-if="showMoreWorkspaces"
          class="home-page-search-modal__bottom-bar"
        >
          <DmButton
            title="Pokaż mniej"
            secondary
            @click.prevent="hideBottomBar"
          />
        </div>

      </section>

      <section
        v-if="formWrapper.form.value.fields.searchServiceOrWorkspace.length >= 3 && !foundServices.length && !foundWorkshops.length && !loading && !typing"
      >
        <div>
          <h4 class="font-weight-500"><span class="color-primary-blue">Ups... </span>nic nie znaleźliśmy</h4>
          <p class="color-grey-3">Jeśli nie możesz znaleźć usługi lub warsztatu, który Cię interesuje, wybierz miasto i
            sprawdź listę wszystkich dostępnych warsztatów w Twojej okolicy.</p>
        </div>
        <DmButton
          @click.prevent="goToCities"
          class="w-100"
          title="Wybierz miasto lub dzielnicę"
        />
      </section>

      <section v-else-if="!loading && !typing">
        <DmDescriptionButton
          icon="i-circle-add"
          class="home-page-search-modal__other-button"
          title="Nie wiesz co wybrać?"
          secondary
          @click.prevent="goToCities"
        >
          <p class="home-page-search-modal__paragraph">Spróbuj wyszukać najpierw miasto</p>
        </DmDescriptionButton>
      </section>
    </div>
  </form>
</template>

<script
  setup
  lang="ts"
>

import { DmDescriptionButton, DmInput, DmButton } from '@dobrymechanik/vue-ui-v2';
import { homePagesearchQueries } from '@/models/consts';
import { EHomePageSearchStep } from '@/models/enums';
import { type IFormCreatorResponse } from '@/composables/useFormCreator';

export interface IHomePageSearchlFormFields {
  searchServiceOrWorkspace: string;
}

interface IProps {
  formWrapper: IFormCreatorResponse<IHomePageSearchlFormFields>;
  foundWorkshops: any[];
  foundServices: any[];
  loading: boolean;
  typing: boolean;
}

const props = defineProps<IProps>();

const router = useRouter();
const route = useRoute();

const showMoreServices = ref(false);
const showMoreWorkspaces = ref(false);

const popularServices = [
  {
    service: 'Diagnostyka komputerowa',
    slug_name: 'diagnostyka-komputerowa-silnika',
    category: 'Mechanika ogólna',
    id: 'b1ed3dd8-c269-4956-9059-3b3e6ea27906'
  },
  {
    service: 'Wymiana oleju',
    slug_name: 'wymiana-oleju-silnikowego',
    category: 'Mechanika ogólna',
    id: '09f59f41-a7c3-44d2-ba0a-4edb7fa6c613'
  },
  {
    service: 'Wymiana opon',
    slug_name: 'wymiana-opon',
    category: 'Wulkanizacja',
    id: 'dbb83bd4-e475-499a-9f9a-79a74654b7c5'
  },
  {
    service: 'Serwis klimatyzacji',
    slug_name: 'klimatyzacja-napelnianie',
    category: 'Serwis klimatyzacji samochodowej',
    id: '5406e9df-7b38-47ff-bb55-249aa9ca6267'
  },
  {
    service: 'Naprawa hamulców',
    slug_name: 'wymiana-klockow-hamulcowych',
    category: 'Serwis klimatyzacji samochodowej',
    id: 'c1661393-0a81-47f2-8a08-2aabdd8cfcd5'
  },
  {
    service: 'Geometria kół',
    slug_name: 'geometria-kol',
    category: 'Geometria kół',
    id: '219f54f3-4c53-4a67-99c7-f4637e6c2036'
  },
];


// watch
watch(() => props.formWrapper.form.value.fields.searchServiceOrWorkspace, (value) => {
  if (value.length < 3) {
    hideBottomBar();
  }
});

// methods
const goToCities = async (e: any) => {
  const routeName = route.name;
  if (!routeName) return;
  router.push({ name: routeName, query: { [homePagesearchQueries.searchStep]: EHomePageSearchStep.CityOrDistrict } });
};

const getWorkshopAddress = (workshop: any) => {
  const maxLength = 30;
  let workshopName = workshop.city;

  if (workshop.quarter) {
    workshopName += `, ${workshop.quarter}`;
  }

  workshopName += `, ${workshop.address}`;

  return workshopName.length > maxLength ? `${workshopName.substring(0, maxLength)}...` : workshopName;
};

const getWorkshopName = (workshop: any) => {
  const maxLength = 24;

  return workshop.name.length > maxLength ? `${workshop.name.substring(0, maxLength)}...` : workshop.name;
};

const hideBottomBar = () => {
  if (showMoreServices.value) {
    showMoreServices.value = false;
  } else if (showMoreWorkspaces.value) {
    showMoreWorkspaces.value = false;
  }
}

</script>

<style lang="scss">
.home-page-search-modal {

  &__form {
    min-height: calc(100vh - 100px);

    @media (min-width: $breakpoint) {
      min-height: unset;
    }
  }

  &__input {
    position: sticky;
    top: 0;
    z-index: 1;
    padding: 16px 0;
    background-color: $white;
  }


  &__section-header {
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    margin: 0 0 0 0;
  }

  &__paragraph {
    font-size: 15px;
    margin: 6px 0;
  }

  &__services {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 0 20px;
    padding: 0px 0 4px 0;
  }

  &__service {
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 68px;
    border-bottom: 1px solid $grey-6;
    width: 100%;
    justify-content: space-between;

    @media (min-width: $breakpoint) {
      width: calc(50% - 10px);
    }
  }

  &__service-wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__service-link {
    color: unset;
  }

  &__service-name {
    font-size: 16px;
    line-height: 22px;
    margin: 0;
    font-weight: 400;
    color: $black;
  }

  &__service-category {
    font-size: 14px;
    line-height: 22px;
    margin: 0;
    font-weight: 400;
    color: $grey-3;
  }

  &__service-photo {}

  &__show-more-wrapper {
    display: flex;
    justify-content: center;
  }


  .description-button__header {
    font-size: 16px !important;
  }

  &__bottom-bar {
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 10px 0;
    border-top: 2px solid var(--White, #FFF);
    background: linear-gradient(171deg, rgba(255, 255, 255, 0.50) 7.05%, #FFF 100.05%);
    backdrop-filter: blur(10px);
    display: flex;
    justify-content: center;
  }

  &__other-button {
    width: 100%;
    margin: 24px 0;
  }
}
</style>