<template>
  <form
    class="home-page-search-modal-city__form"
    id="home-page-search-modal-city.form"
    @submit.prevent="submit"
  >
    <div class="home-page-search-modal-city">
      <div class="home-page-search-modal__input">
        <DmInput
          v-model="formWrapper.form.value.fields.searchCityOrDistrict"
          class="home-page-search-modal-cityh__search-input"
          icon="i-search"
          id="homePageSearchCityOrDistrict"
          label="Wyszukaj miasto lub dzielnicę"
          placeholder="np. Warszawa"
          @update:errors="formWrapper.updateErrors($event, 'searchCityOrDistrict')"
        />
      </div>
      <section v-if="!!foundCities.length">
        <h4 class="home-page-search-modal-city__section-header">Miasta</h4>
        <ul class="home-page-search-modal-city__cities">
          <li
            class="home-page-search-modal-city__city"
            v-for="(city, index) in foundCities"
            v-show="index < 4"
          >
            <NuxtLink
              class="home-page-search-modal-city__city-link"
              target="_blank"
              :to="serviceFromQuery
      ? `https://dobrymechanik.pl/uslugi/${serviceFromQuery}/${city.city_slug}/`
      : `https://dobrymechanik.pl/mechanicy/${city.city_slug}/`"
            >
              <div class="home-page-search-modal-city__city-wrapper">
                <NuxtImg
                  src="/_nuxt/fullPin.svg"
                  height="26px"
                  width="26px"
                  :alt="city.city"
                />
                <div>
                  <h5 class="home-page-search-modal-city__city-name">{{ city.city }}</h5>
                </div>

              </div>
              <i class="i-arrow-right" />
            </NuxtLink>
          </li>
        </ul>
      </section>
      <section v-if="!!foundDistricts.length">
        <h4 class="home-page-search-modal-city__section-header">Dzielnice</h4>
        <ul class="home-page-search-modal-city__cities">
          <li
            class="home-page-search-modal-city__city"
            v-for="(district, index) in foundDistricts"
          >
            <NuxtLink
              class="home-page-search-modal-city__city-link"
              target="_blank"
              :to="serviceFromQuery
      ? `https://dobrymechanik.pl/uslugi/${serviceFromQuery}/${district.city_slug}/dzielnica/${district.quarter_slug}/`
      : `https://dobrymechanik.pl/mechanicy/${district.city_slug}/dzielnica/${district.quarter_slug}/`"
            >
              <div class="home-page-search-modal-city__city-wrapper">

                <NuxtImg
                  src="/_nuxt/fullPin.svg"
                  height="26px"
                  width="26px"
                  :alt="district.quarter"
                />
                <div>
                  <h5 class="home-page-search-modal-city__city-name">{{ district.quarter }}</h5>
                  <p class="home-page-search-modal-city__city-subname">{{ district.city }}</p>
                </div>

              </div>
              <i class="i-arrow-right" />
            </NuxtLink>
          </li>
        </ul>
      </section>

      <section
        v-if="!foundCities.length && !foundDistricts.length && formWrapper.form.value.fields.searchCityOrDistrict.length >= 3 && !typing && !loading"
      >
        <div>
          <h4 class="font-weight-500"><span class="color-primary-blue">Ups... </span>nie znaleźliśmy wasztatów</h4>
          <p class="color-grey-3">Jeśli nie możesz znaleźć lokalizacji, która Cię interesuje, wybierz jedno z
            popularnych miast lub wyszukaj większą miejscowość w Twojej okolicy.

          </p>
        </div>
        <NuxtLink
          target="_blank"
          class="button button--primary w-100 mb-5"
          to="https://dobrymechanik.pl/mapa"
        >Szukaj na mapie
        </NuxtLink>
      </section>

      <section v-if="!foundCities.length && !foundDistricts.length">
        <h4 class="home-page-search-modal-city__section-header">Popularne miasta</h4>
        <ul class="home-page-search-modal-city__cities">
          <li
            class="home-page-search-modal-city__city"
            v-for="city in homePageCities"
          >
            <NuxtLink
              class="home-page-search-modal-city__city-link"
              target="_blank"
              :to="!!serviceFromQuery
      ? `https://dobrymechanik.pl/uslugi/${serviceFromQuery}/${city.slug}/`
      : `https://dobrymechanik.pl/mechanicy/${city.slug}/`"
            >
              <div class="home-page-search-modal-city__city-wrapper">
                <NuxtImg
                  src="/_nuxt/fullPin.svg"
                  height="26px"
                  width="26px"
                  :alt="city.name"
                />
                <div>
                  <h5 class="home-page-search-modal-city__city-name">{{ city.name }}</h5>
                </div>
              </div>
              <i class="i-arrow-right" />
            </NuxtLink>
          </li>
        </ul>
      </section>
    </div>
  </form>
</template>

<script
  setup
  lang="ts"
>

import { DmInput, DmButton } from '@dobrymechanik/vue-ui-v2';
import { type IFormCreatorResponse } from '@/composables/useFormCreator';
import { type IHomePageSearchlFormFields } from '@/pages/index.vue';
import { homePageCities } from '@/models/consts';

interface IProps {
  formWrapper: IFormCreatorResponse<IHomePageSearchlFormFields>;
  foundCities: any[];
  foundDistricts: any[];
  loading: boolean;
  typing: boolean;
}

const props = defineProps<IProps>();

const router = useRouter();
const route = useRoute();

// computed
const serviceFromQuery = computed(() => {
  return route.query['service_slug'] || props.formWrapper.form.value.fields.serviceSlugName;
});

// methods
const submit = async (e: any) => {
  const isFormValid = await props.formWrapper.checkFormValidity(e.target.id);

  if (isFormValid) {
    const routeName = route.name;
    if (!routeName) return;
  }
}

</script>

<style lang="scss">
.home-page-search-modal-city {
  &__form {
    min-height: calc(100vh - 100px);

    @media (min-width: $breakpoint) {
      min-height: unset;
    }
  }


  &__input {
    position: sticky;
    top: 0;
    z-index: 1;
    padding: 16px 0;
    background-color: $white;
  }


  &__section-header {
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    margin: 0 0 0 0;
  }

  &__paragraph {
    font-size: 15px;
    margin: 6px 0;
  }

  &__cities {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 0 20px;
    padding: 0px 0 4px 0;
  }

  &__city {
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 68px;
    border-bottom: 1px solid $grey-6;
    width: 100%;
    justify-content: space-between;

    @media (min-width: $breakpoint) {
      width: calc(50% - 10px);
    }
  }

  &__city-link {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    color: $grey-1;
    align-items: center;
    gap: 8px;
    text-decoration: none;
  }

  &__city-wrapper {
    display: flex;
    gap: 8px;
    align-items: center;
  }

  &__city-name {
    font-size: 16px;
    line-height: 22px;
    margin: 0;
    font-weight: 400;
    text-transform: capitalize;
  }

  &__city-subname {
    font-size: 16px;
    line-height: 22px;
    margin: 0;
    font-weight: 400;
    color: $grey-3;
    text-transform: capitalize;
  }

  .description-button__header {
    font-size: 16px !important;
  }
}
</style>