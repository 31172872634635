export const homePagesearchQueries = {
  searchStep: 'search-step',
};

export const homePageServices = [
  {
    name: 'wymiana opon',
    slug: 'wymiana-opon'
  },
  {
    name: 'serwis klimatyzacji',
    slug: 'serwis-klimatyzacji-samochodowej'
  },
  {
    name: 'wymiana oleju',
    slug: 'wymiana-oleju-w-silniku'
  },
  {
    name: 'wymiana rozrządu',
    slug: 'wymiana-paska-rozrzadu'
  },
  {
    name: 'geometria kół',
    slug: 'geometria-kol'
  },
  {
    name: 'wymiana sprzęgła',
    slug: 'wymiana-sprzegla'
  },
  {
    name: 'wymiana klocków',
    slug: 'wymiana-klockow-hamulcowych'
  },
  {
    name: 'instalacje LPG',
    slug: 'instalacje-lpg-auto-gaz'
  }
];

export const homePageCities = [
  {
    name: "Białystok",
    slug: 'bialystok',
    services: homePageServices
  },
  {
    name: "Bydgoszcz",
    slug: 'bydgoszcz',
    services: homePageServices
  },
  {
    name: "Częstochowa",
    slug: 'czestochowa',
    services: homePageServices
  },
  {
    name: "Gdańsk",
    slug: 'gdansk',
    services: homePageServices
  },
  {
    name: "Gdynia",
    slug: 'gdynia',
    services: homePageServices
  },
  {
    name: "Katowice",
    slug: 'katowice',
    services: homePageServices
  },
  {
    name: "Kielce",
    slug: 'kielce',
    services: homePageServices
  },
  {
    name: "Kraków",
    slug: 'krakow',
    services: homePageServices
  },
  {
    name: "Lublin",
    slug: 'lublin',
    services: homePageServices
  },
  {
    name: "Łódź",
    slug: 'lodz',
    services: homePageServices
  },
  {
    name: "Olsztyn",
    slug: 'olsztyn',
    services: homePageServices
  },
  {
    name: "Poznań",
    slug: 'poznan',
    services: homePageServices
  },
  {
    name: "Rzeszów",
    slug: 'rzeszow',
    services: homePageServices
  },
  {
    name: "Szczecin",
    slug: 'szczecin',
    services: homePageServices
  },
  {
    name: "Warszawa",
    slug: 'warszawa',
    services: homePageServices
  },
  {
    name: "Wrocław",
    slug: 'wroclaw',
    services: homePageServices
  }
];

export const homePageCarsCities = [
  {
    name: "Gdańsk",
    slug: 'gdansk'
  },
  {
    name: "Kraków",
    slug: 'krakow'
  },
  {
    name: "Poznań",
    slug: 'poznan'
  },
  {
    name: "Szczecin",
    slug: 'szczecin'
  },
  {
    name: "Warszawa",
    slug: 'warszawa'
  },
  {
    name: "Wrocław",
    slug: 'wroclaw'
  },
  {
    name: "Łódź",
    slug: 'lodz'
  }
];

export const homePageBrands = [
  {
    name: 'Alfa Romeo',
    slug: 'alfa-romeo',
    cities: homePageCarsCities
  },
  {
    name: 'Audi',
    slug: 'audi',
    cities: homePageCarsCities
  },
  {
    name: 'BMW',
    slug: 'bmw',
    cities: homePageCarsCities
  },
  {
    name: 'Chevrolet',
    slug: 'chevrolet',
    cities: homePageCarsCities
  },
  {
    name: 'Chrysler',
    slug: 'chrysler',
    cities: homePageCarsCities
  },
  {
    name: 'Citroen',
    slug: 'citroen',
    cities: homePageCarsCities
  },
  {
    name: 'Dacia',
    slug: 'dacia',
    cities: homePageCarsCities
  },
  {
    name: 'Fiat',
    slug: 'fiat',
    cities: homePageCarsCities
  },

  {
    name: 'Ford',
    slug: 'ford',
    cities: homePageCarsCities
  },
  {
    name: 'Honda',
    slug: 'honda',
    cities: homePageCarsCities
  },
  {
    name: 'Hyundai',
    slug: 'hyundai',
    cities: homePageCarsCities
  },
  {
    name: 'Jeep',
    slug: 'jeep',
    cities: homePageCarsCities
  },
  {
    name: 'Kia',
    slug: 'kia',
    cities: homePageCarsCities
  },
  {
    name: 'Land Rover',
    slug: 'land-rover',
    cities: homePageCarsCities
  },
  {
    name: 'Lexus',
    slug: 'lexus',
    cities: homePageCarsCities
  },
  {
    name: 'Mazda',
    slug: 'mazda',
    cities: homePageCarsCities
  }
];
