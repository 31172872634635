<template>
  <section class="home-page-search">
    <div class="home-page-search__wrapper">
      <div class="home-page-search__description">
        <h1 class="home-page-search__title">Znajdź warsztat <span>i umów się online</span></h1>
        <h2 class="home-page-search__subtitle">Przeglądaj opinie, przebieraj w terminach, rezerwuj online.</h2>
      </div>

      <div class="home-page-search__bar">
        <div class="home-page-search__bar-search-wrapper">
          <div
            @click="openModal(EHomePageSearchStep.ServiceOrWorkshop)"
            class="home-page-search__bar-search"
          >
            <NuxtImg
              src="/_nuxt/magnifyingGlassBlack.svg"
              width="20px"
              height="20px"
              alt="Usługa lub warsztat"
            />
            {{ formFields.serviceName || 'Usługa lub warsztat' }}
          </div>

          <div
            @click="openModal(EHomePageSearchStep.CityOrDistrict)"
            class="home-page-search__bar-search"
          >
            <NuxtImg
              src="/_nuxt/fullPin.svg"
              width="20px"
              height="20px"
              alt="Miasto lub dzielnica"
            />
            {{ 'Miasto lub dzielnica' }}
          </div>
        </div>
        <DmButton
          @click="search"
          class="home-page-search__bar-button"
          title="Znajdź warsztat"
        />
      </div>
    </div>
    <div
      class="home-page-search__scroll-down"
      @click="scrollDown"
      role="button"
    >
      <NuxtImg
        src="/_nuxt/scrollDown.svg"
        width="20px"
        height="20px"
        alt="Przewiń w dół"
      />
      Przewiń w dół
    </div>
  </section>
</template>

<script
  setup
  lang="ts"
>
import { DmButton } from '@dobrymechanik/vue-ui-v2';
import { EHomePageSearchStep } from '@/models/enums';
import { homePagesearchQueries } from '@/models/consts';
import { type IHomePageSearchlFormFields } from '@/pages/index.vue';

interface IProps {
  formFields: IHomePageSearchlFormFields;
}

// props
const props = defineProps<IProps>();

// use
const router = useRouter();
const route = useRoute();

// methods
const scrollDown = () => {
  window.scrollTo({
    top: 700,
    behavior: 'smooth'
  });
};

const search = () => {
  const routeName = route.name;
  if (!routeName) return;

  if (!!props.formFields.serviceSlugName) {
    router.push({ name: routeName, query: { [homePagesearchQueries.searchStep]: EHomePageSearchStep.CityOrDistrict } });
    return;
  } else {
    router.push({ name: routeName, query: { [homePagesearchQueries.searchStep]: EHomePageSearchStep.ServiceOrWorkshop } });
  }
};

const openModal = (searchStep: EHomePageSearchStep) => {
  const routeName = route.name;
  if (!routeName) return;

  router.push({ name: routeName, query: { [homePagesearchQueries.searchStep]: searchStep } });
};

// watch
watch(() => route.query, (queries) => {
  if (queries.service) {
    props.formFields.serviceName = queries.service as string;
  }

  if (queries['service-slug']) {
    props.formFields.serviceSlugName = queries['service-slug'] as string;
  }
});

</script>

<style
  scoped
  lang="scss"
>
.home-page-search {
  background-image: url(/_nuxt/main.png);
  background-size: cover;
  display: flex;
  justify-content: center;
  min-height: 580px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 32px 0;
  box-sizing: border-box;

  @media (min-width: $breakpoint) {
    background-image: url(/_nuxt/main-pc.png);
    background-position: top right;
    height: 760px;
    align-items: center;
    justify-content: center;
  }

  &__wrapper {
    max-width: 1200px;
    padding: 0 14px;
    width: 100%;
    gap: 20px 0;
    display: flex;
    flex-direction: column;

    @media (min-width: $breakpoint) {
      gap: 40px 0;
    }
  }


  &__description {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 40px;
    gap: 10px 0;

    @media (min-width: $breakpoint) {
      flex-direction: column-reverse;
      margin-top: unset;
      gap: unset;
    }
  }

  &__title {
    color: $white;
    text-align: center;
    font-size: 32px;
    font-weight: 500;
    line-height: 42px;
    margin: 0;
    text-align: center;

    span {
      white-space: nowrap;
    }

    @media (min-width: $breakpoint) {
      font-size: 60px;
      line-height: 85px;
      text-align: left;
    }
  }


  &__subtitle {
    color: $grey-5;
    line-height: 28px;
    font-size: 16px;
    font-weight: 400;
    margin: 0;
    text-align: center;

    @media (min-width: $breakpoint) {
      font-size: 24px;
      line-height: 34px;
      text-align: left;
    }
  }

  &__bar {
    background-color: $white;
    padding: 14px;
    border-radius: 22px;
    display: flex;
    min-height: 92px;
    cursor: pointer;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 14px 0;

    @media (min-width: $breakpoint) {
      flex-direction: row;
      gap: unset;
      padding: 10px;
      border-radius: 18px;
    }
  }

  &__bar-search-wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 10px 0;

    @media (min-width: $breakpoint) {
      flex-direction: row;
      gap: unset;
    }
  }

  &__bar-search {
    flex: 1;
    display: flex;
    align-items: center;
    padding: 0 14px;
    gap: 0 14px;
    background-color: $grey-6;
    border: 1px solid $grey-5;
    border-radius: 8px;
    min-height: 56px;
    font-size: 16px;

    @media (min-width: $breakpoint) {
      background-color: unset;
      border: unset;
      border-radius: unset;
      padding: 0 20px;
      font-size: 20px;
      line-height: 28px;

      &:first-child {
        border-right: 2px solid $blue-5;
      }
    }
  }

  &__scroll-down {
    display: flex;
    flex-direction: column;
    color: $blue-4;
    align-items: center;
    gap: 5px 0;
    cursor: pointer;

    @media (min-width: $breakpoint) {
      display: none;
    }
  }
}
</style>