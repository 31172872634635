<template>
  <div class="home-page-benefits">
    <div
      class="home-page-benefits__element"
      v-for="benefit in benefits"
    >
      <NuxtImg
        :src="`/_nuxt/${benefit.icon}.svg`"
        alt="Adres serwisu"
        width="80px"
        height="80px"
      />
      <h2 class="home-page-benefits__element-title">{{ benefit.title }}</h2>
      <p class="home-page-benefits__element-description">{{ benefit.description }}</p>
    </div>
  </div>
</template>

<script
  setup
  lang="ts"
>

const benefits = [
  {
    title: 'Sprawdzone warsztaty blisko Ciebie',
    description: 'Wybierz warsztat z wysokimi ocenami od zweryfikowanych kierowców.',
    icon: 'benefit-workshops'
  },
  {
    title: 'Łatwe umawianie wizyt online',
    description: 'Zarezerwuj termin online o każdej porze, bez konieczności dzwonienia.',
    icon: 'benefit-booking'
  },
  {
    title: 'Przypomnienia o wizytach',
    description: 'Otrzymuj przypomnienia o wizycie i łatwo zmieniaj termin w razie potrzeby.',
    icon: 'benefit-notifications'
  }
]

</script>

<style
  scoped
  lang="scss"
>
.home-page-benefits {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  padding: 40px 14px;

  @media (min-width: $breakpoint) {
    flex-direction: row;
    align-items: unset;
    padding: 60px 14px;
  }

  &__element {
    max-width: 404px;
    width: 100%;
    background-color: $blue-6;
    border-radius: 20px;
    padding: 32px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 8px 0;
  }

  &__element-title {
    margin: 0;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    margin-top: 4px;
  }

  &__element-description {
    color: $grey-2;
    text-align: center;
    font-family: Rubik;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    margin: 0;
  }
}
</style>