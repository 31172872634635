<template>
  <article class="home-page-brands">
    <div class="home-page-brands__wrapper">
      <h3 class="home-page-brands__header">Marki samochodów</h3>

      <ul class="home-page-brands__lists">
        <li
          v-for="brand in homePageBrands"
          class="home-page-brands__list"
        >
          <a
            class="home-page-brands__services-parent-name"
            :href="`https://dobrymechanik.pl/specjalizacje/${brand.slug}/`"
          >
            <span>{{ brand.name }}</span>
          </a>
          <ul class="home-page-brands__services">
            <li
              v-for="city in brand.cities"
              :key="city.name"
              class="home-page-brands__service"
            >
              <a :href="`https://dobrymechanik.pl/specjalizacje/${brand.slug}/${city.slug}/`">
                <span>{{ city.name }}</span>
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </article>
</template>

<script
  setup
  lang="ts"
>
import { homePageBrands } from '@/models/consts';

// use
const route = useRoute();

// computed
const city = computed(() => {
  return route.params['city'] as string;
});


</script>

<style
  lang="scss"
  scoped
>
.home-page-brands {
  display: flex;
  justify-content: center;

  &__wrapper {
    width: 100%;
    max-width: 1280px;
    padding: 0 14px;
  }

  &__header {
    margin: 0;
    font-size: 20px;
    font-weight: 400;
  }

  &__lists {
    display: flex;
    flex-wrap: wrap;
    margin: 24px 0;
    list-style: none;
    padding: 0;
    gap: 0 40px;
  }

  &__list {
    margin: 0 0 20px 0;
    display: flex;
    flex-direction: column;
  }

  &__services {
    list-style: none;
    padding: 0;
  }

  &__services-parent-name {
    font-weight: 500;
    font-size: 14px;
    margin: 0 0 8px 0;
    color: $blue-1 !important;
    text-decoration: none;
  }

  &__service {
    min-width: 110px;
    font-size: 16px;
    line-height: 26px;
    margin: 4px 0;

    a {
      text-decoration: none;
      color: $grey-2 !important;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>